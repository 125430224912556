/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"

import services from "services"
import { handlePermissionsStorage } from "../../utils"
import { navigate } from "gatsby-link"

const useCallbackLogin = ({ code, onFailure }) => {
	const handleFailure = (message = "") => {
		onFailure(message)
		navigate("/autenticacao/login")
	}

	const handleOuath = (data) => {
		const { error, message } = data
		if (!!error) return handleFailure(message)

		handlePermissionsStorage(data)
	}

	useEffect(() => {
		if (!code) return

		services.auth.oauth({ code }).then(handleOuath)
	}, [code])
}

export default useCallbackLogin
