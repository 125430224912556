import TextDivider from '../TextDivider'
import './FormLogin.scss'
import { values, validation } from './form'
import useFormLogin from './hooks'
import { yupResolver } from '@hookform/resolvers/yup'
import { Button, Fields } from '@redsales/ds'
// import Fields from 'components/Fields'
import AuthError from 'components/Auth/Error'
import AuthFeedback from 'components/Auth/Feedback'
import If from 'components/If'
import { Link } from "gatsby-link"
import { HAS_EXTERNAL_LOGIN } from 'gatsby-env-variables'
import { useForm as useFormHook } from 'react-hook-form'

const FormLogin = ({ oauth }) => {
	const { oauthErrors, onSubmit, onConcluded, isRequestingAPI } = useFormLogin({
		oauth,
	})

	const {
		control,
		handleSubmit,
		formState: { isValid },
	} = useFormHook({
		defaultValues: values,
		mode: 'onChange',
		...(validation && { resolver: yupResolver(validation) }),
	})

	const shouldShowFormLogin = !oauthErrors.locked && !oauthErrors.expired

	return (
		<>
			<If condition={shouldShowFormLogin}>
				<h1 className="form-login__title logged-out__title">
					Acesse sua conta
				</h1>

				<If condition={oauthErrors.message || oauth?.message}>
					<AuthError
						data-test="error"
						title="Email ou senha inválido"
						message={oauthErrors.message || oauth?.message}
					/>
				</If>

				<form className="form-login" onSubmit={handleSubmit(onSubmit)}>
					<If condition={HAS_EXTERNAL_LOGIN}>
						<Button
							external
							type="button"
							variant="group"
							data-test="iu-auth"
							className="form-login__iu-auth"
							link={'www.google.com.br'}
						>
							Login com IU Acessos
						</Button>

						<TextDivider className="form-login__divider">ou</TextDivider>
					</If>

					<Fields.Input.Text
						name="login"
						control={control}
						placeholder="Digite seu email"
					/>

					<Fields.Input.Password
						type="password"
						name="password"
						control={control}
						placeholder="Digite sua senha"
					/>

					<Button
						size="big"
						type="submit"
						className="form-login__submit"
						isLoading={isRequestingAPI}
						disabled={!isValid || isRequestingAPI}
					>
						Acessar minha conta
					</Button>
				</form>

				<Link
					className="form-login__forgot-password"
					to={ROUTES.forgotPassword.path}
				>
					Esqueci minha senha
				</Link>
			</If>

			<If condition={oauthErrors.locked}>
				<AuthFeedback
					icon="lock"
					title="Acesso bloqueado"
					description={`
						<span>
							Você excedeu o limite de 5 tentativas de acesso com senhas
							inválidas.
							<span className="divider" />
							Por segurança, o seu acesso foi bloqueado. Entre em contato com o
							administrador da sua conta para solicitar o desbloqueio.
						</span>
						`}
					button="Concluído"
					submitComponent="link"
					onClick={onConcluded}
					data-test="auth-feedback"
				/>
			</If>

			<If condition={oauthErrors.expired}>
				<AuthFeedback
					icon="lock"
					title="Senha expirada"
					description={`
							A sua senha expirou em [data].
							<span className="divider" />
							Para voltar a acessar a plataforma, clique no botão ‘Recadastrar
							senha’ e siga as instruções.
					 `}
					button="Recadastrar senha"
					onClick={onConcluded}
					data-test="auth-feedback"
				/>
			</If>
		</>
	)
}

export default FormLogin
