import { PERMISSIONS } from 'constants/permissions'
import { navigate } from 'gatsby'
import services from 'services'
import auth from 'utils/auth'
import storage from 'utils/storage'

const goToHome = (canNavigate = false) =>
	canNavigate && navigate(ROUTES.upload.path)

const handleStorage = (
	{ permissions = [], is_power_user = false, ...userInfo } = {},
	canNavigate = false
) => {
	const roleId = userInfo?.role?.id
	const isPowerUser = roleId <= 2
	const isPermissionValid =
		!!permissions && Array.isArray(permissions) && permissions.length > 0

	if (isPermissionValid || !!is_power_user || isPowerUser) {
		const powerUserPermission =
			is_power_user || isPowerUser
				? PERMISSIONS.POWER_USER.GRANTED
				: PERMISSIONS.POWER_USER.REVOKED

		storage.session.setItem(
			'permissions',
			JSON.stringify([permissions, powerUserPermission].flat())
		)

		const currentUserInfo = JSON.parse(storage.session.getItem('userInfo'))
		storage.session.setItem(
			'userInfo',
			JSON.stringify({
				...currentUserInfo,
				permissions,
				is_power_user: is_power_user || isPowerUser,
				...userInfo,
			})
		)
		canNavigate = true
	}

	return canNavigate
}

export const handlePermissionsStorage = (
	{ token, ...props } = {},
	onError = () => {}
) => {
	if (token) {
		handleStorage({ token, ...props })
		goToHome(true)
	}
}
