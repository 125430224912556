// import Loader from "components/Loader"

import useCallbackLogin from "./hooks"
import "./CallbackLogin.scss"

const CallbackLogin = ({ code, onFailure }) => {
	useCallbackLogin({ code, onFailure })

	return (
		<section className="callback-login">
			<span className="callback-login__message">Verificando...</span>
		</section>
	)
}

export default CallbackLogin
