import { Icon } from "@redsales/ds"
import If from "components/If"
import "./AuthError.scss"

const AuthError = ({ title, message, ...props }) => (
	<div className="auth-error" {...props}>
		<Icon name="error" className="auth-error__icon" />

		<div className="auth-error__wrapper">
			<If condition={title}>
				<p className="auth-error__title">{title}</p>
			</If>

			<p className="auth-error__message">{message}</p>
		</div>
	</div>
)

export default AuthError
