import { handlePermissionsStorage } from '../../utils'
import { useState } from 'react'
import services from 'services'

const initialStates = {
	expired: false,
	locked: false,
	message: false,
}

const useFormLogin = ({ oauth }) => {
	const [oauthErrors, setOauthErrors] = useState(initialStates)
	const [isRequestingAPI, setIsRequestingAPI] = useState(false)

	const onSubmit = (formData) => {
		setIsRequestingAPI(true)
		oauth.resetMessage()
		services.auth
			.login(formData)
			.then(handleAuthentication)
			.then((dataFromAPI) => handlePermissionsStorage(dataFromAPI, onError))
			.catch(({ data }) => {
				onError(data?.message)
			})
			.finally(() => setIsRequestingAPI(false))
	}

	const onConcluded = () => {
		setOauthErrors(initialStates)
	}

	const onError = (message) => setOauthErrors({ ...initialStates, message })

	const handleAuthentication = ({ error, token, message, role }) => {
		if (error && message === 'Usuário bloqueado') {
			return setOauthErrors({ ...initialStates, locked: true })
		}

		if (error && message === 'Senha expirada') {
			return setOauthErrors({ ...initialStates, locked: false })
		}

		if (error) {
			return setOauthErrors({ ...initialStates, message })
		}

		return { token, role }
	}

	return {
		oauthErrors,
		onSubmit,
		onConcluded,
		isRequestingAPI,
	}
}

export default useFormLogin
