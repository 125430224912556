import CallbackLogin from './components/CallbackLogin'
import FormLogin from './components/FormLogin'
import useLogin from './hooks'
import If from 'components/If'

const Login = () => {
	const { code, oauth } = useLogin()

	return (
		<div className="form-login__container">
			<If
				condition={!!code}
				renderIf={<CallbackLogin code={code} onFailure={oauth.setMessage} />}
				renderElse={<FormLogin oauth={oauth} />}
			/>
		</div>
	)
}

export default Login
