import { useParameter } from "hooks/useParameters"
import { useState } from "react"

const useLogin = () => {
  const code = useParameter("code")
  const [message, setMessage] = useState(null)

  return {
    code,
    oauth: {
      message,
      setMessage,
      resetMessage: () => setMessage(null),
    },
  }
}

export default useLogin
